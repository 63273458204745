.aboutContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  width: 80%;
  margin-top: 20px;
}
.aboutWrapper {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.aboutContent1 {
  /* border: solid red; */
  width: 50%;
  height: 100%;
}
.aboutImgContainer {
  /* border: solid; */
  height: 100%;
}
.aboutImgContainer > img {
  height: 100%;
  width: 100%;
}
.aboutContent2 {
  /* border: solid blue; */
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.aboutHeadContainer {
  /* border: solid brown; */
  height: 15%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutHeadContainer > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  font-size: 30px;
}
.aboutHeadContainer > h2> span {
  color: var(--customColor);
}
.aboutTxtContainer {
  /* border: solid teal; */
  height: 20%;
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--customColor);
}
.aboutTxtContent {
  /* border: solid; */
  width: 100%;
}
.aboutTxtItem {
  /* border: solid greenyellow; */
  height: 50%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.aboutTxtItem > h2 {
  color: white;
}
.aboutTxtItem > p {
  font-size: 20px;
  font-weight: bold;
  color: white;
}
.aboutTxtContent {
  /* border: solid; */
  width: 30%;
  height: 90%;
}

.aboutParaContainer {
  /* border: solid green; */
  height: 60%;
}
.aboutParaContainer > p {
  font-size: 20px;
  margin: 20px;
}
@media screen and (min-width: 320px) and (max-width: 990px) {
  .aboutContainer {
    /* border: solid; */
    gap: 20px;
    min-width: 300px;
    margin-top: 20px;
  }
  .aboutWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .aboutContent1 {
    /* border: solid red; */
    height: 100%;
    width: 100%;
  }

  .aboutContent2 {
    /* border: solid blue; */
    height: 60vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
  .aboutTxtContainer {
    /* border: solid teal; */
    height: 20%;
    width: 100%;
  }
  .aboutTxtContent {
    /* border: solid; */
    width: 100%;
  }
  .aboutTxtItem {
    /* border: solid greenyellow; */
    height: 50%;
    width: 100%;
  }
  .aboutTxtItem > p {
    font-size: 17px;
    text-align: center;
  }
}

@media screen and (min-width: 990px) {
  .aboutContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .aboutContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin-top: 20px;
    padding: 0px;
  }
}
