.interiorTypeContainer {
  /* border: solid red; */
  width: 100%;
  padding: 10px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.interiorTypeHeadContainer > h1 {
  text-align: center;
}
.interiorTypeHeadContainer > h1 > span {
  color: var(--customColor);
}

.interiorTypeParaContainer {
  /* border: solid; */
  font-size: 20px;
  width: 80%;
}
.interiorTypeMainContainer {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.interiorTypeCardContainer {
  /* border: solid red; */
  width: 17%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 0px 10px black;
  background-color: white;
  transition: 300ms ease-in-out;
}

.interiorTypeCardContainer > a {
  /* border: solid; */
  margin: 0;
  color: var(--customColor);
  text-decoration: none;
}
.interiorTypeCardImg {
  /* border: solid blue; */
  width: 100%;
  object-fit: cover;
  margin: 0;
  height: 50%;
}
.interiorTypeCardImg > img {
  width: 100%;
  height: 100%;
}
.interiorTypeCardHead {
  /* border: solid; */
  text-align: center;
}
.interiorTypeCardPara {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.interiorTypeCardPara > p {
  border: solid transparent;
  width: 90%;
  text-align: center;
  font-size: 20px;
  color: var(--customColor);
}
.interiorTypeCardContainer:hover {
  transform: scale(1.1);
}
.interiorTypeCardContainer:hover .interiorTypeCardHead > h2 {
  color: var(--customColor);
}
.interiorTypeCardContainer:hover .interiorTypeCardPara > p {
  color: black;
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .interiorTypeCardContainer {
    /* border: solid red; */
    width: 300px;
    height: 100%;
  }

  .interiorTypeCardPara > p {
    width: 90%;
    text-align: center;
    font-size: 20px;
    color: var(--customColor);
  }
}
@media screen and (min-width: 768px) {
  .interiorTypeCardContainer {
    /* border: solid red; */
    width: 30%;
    height: 100%;
  }
  .interiorTypeCardHead {
    /* border: solid; */
    font-size: 14px;
  }
  .interiorTypeCardPara > p {
    width: 90%;
    text-align: center;
    font-size: 17px;
    color: var(--customColor);
  }
}
@media screen and (min-width: 990px) {
  .interiorTypeMainContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .interiorTypeCardContainer {
    /* border: solid red; */
    width: 20%;
    height: 100%;
    margin: 20px;
  }
  .interiorTypeCardHead {
    /* border: solid; */
    font-size: 16px;
    text-align: center;
  }
  .interiorTypeCardPara > p {
    border: solid transparent;
    width: 100%;
    font-size: 110%;
  }
}
@media screen and (min-width: 1200px) {
  .interiorTypeParaContainer {
    /* border: solid; */
    width: 90%;
  }
  .interiorTypeCardContainer {
    /* border: solid red; */
    width: 20%;
    height: 100%;
    margin: 25px;
  }
  .interiorTypeCardPara > p {
    border: solid transparent;
    width: 90%;
    font-size: 20px;
  }
}
