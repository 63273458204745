.homeFormContainer {
  /* border: solid green; */
  width: 350px;
  height: 90%;
  backdrop-filter: blur(10px);
  background-color: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0px;
  position: absolute;
  right: 10px;
}

.homeFormContainer > form {
  /* border: solid red; */
  width: 100%;
  height: 100%;
}
.homeFormContainer > form > fieldset {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.homeFormContainer > form > fieldset > legend {
  font-size: 150%;
  text-align: center;
  color: black;
  /* border: solid black; */
  width: 100%;
}
.homeFormContainer > form > fieldset > table {
  /* border: solid yellow; */
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.homeFormContainer > form > fieldset > table > tr {
  /* border: solid purple; */
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
}

.homeFormContainer > form > fieldset > table > tr > td {
  border-bottom: solid grey;
  width: 100%;
  height: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeFormContainer > form > fieldset > table > tr > td > input {
  font-size: 100%;
  width: 90%;
  height: 90%;
  border: none;
  outline: none;
}
.homeFormContainer > form > fieldset > table > button {
  border: solid var(--customColor);
  width: 80%;
  border-radius: 10px;
  font-size: 20px;
  background-color: white;
  color: white;
  background-color: var(--customColor);
  text-align: center;
  transition: 200ms ease-in-out;
}
.homeFormContainer > form > fieldset > table > button:hover {
  color: var(--customColor);
  background-color: white;
  cursor: pointer;
}
.formIcon {
  font-size: 20px;
}
.respHomeFormContainer {
  /* border: solid green; */
  width: 100%;
  height: 70vh;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.respHomeFormContainer > form {
  /* border: solid red; */
  width: 90%;
  height: 100%;
  background-color: rgb(223, 223, 223);
}
.respHomeFormContainer > form > fieldset {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.respHomeFormContainer > form > fieldset > legend {
  font-size: 150%;
  text-align: center;
  color: black;
  /* border: solid black; */
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.respHomeFormContainer > form > fieldset > table {
  /* border: solid yellow; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}
.respHomeFormContainer > form > fieldset > table > tr {
  /* border: solid purple; */
  width: 60%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 30px;
  background-color: rgb(223, 223, 223);
}

.respHomeFormContainer > form > fieldset > table > tr > td {
  border-bottom: solid black;
  width: 100%;
  height: 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(223, 223, 223);
}

.respHomeFormContainer > form > fieldset > table > tr > td > input {
  /* background-color: transparent; */
  font-size: 100%;
  width: 90%;
  height: 90%;
  border: none;
  outline: none;
  background-color: rgb(223, 223, 223);
}
.respHomeFormContainer > form > fieldset > table > button {
  border: solid var(--customColor);
  width: 80%;
  border-radius: 10px;
  font-size: 20px;
  background-color: white;
  color: white;
  background-color: var(--customColor);
  text-align: center;
}

.formIcon {
  font-size: 20px;
}
@media screen and (min-width: 320px) {
  .homeFormContainer {
    /* border: solid green; */
    display: none;
  }
  .respHomeFormContainer {
    /* border: solid green; */
    display: flex;
    width: 100%;
  }
  .respHomeFormContainer > form {
    /* border: solid red; */
    width: 90%;
    height: 100%;
    background-color: rgb(223, 223, 223);
  }
}

@media screen and (min-width: 768px) {
  .homeFormContainer {
    /* border: solid green; */
    display: none;
  }
  .respHomeFormContainer {
    /* border: solid green; */
    display: flex;
    width: 100%;
  }
  .respHomeFormContainer > form {
    /* border: solid red; */
    width: 50%;
    height: 100%;
    background-color: rgb(223, 223, 223);
  }
}
@media screen and (min-width: 990px) {
  .homeFormContainer {
    /* border: solid green; */
    width: 300px;
    height: 95%;
    backdrop-filter: blur(10px);
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    position: absolute;
    right: 10px;
  }

  .homeFormContainer > form {
    /* border: solid red; */
    width: 100%;
    height: 100%;
  }
  .homeFormContainer > form > fieldset {
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeFormContainer > form > fieldset > legend {
    font-size: 150%;
    text-align: center;
    color: grey;
    /* border: solid black; */
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Quicksand", sans-serif;
  }
  .homeFormContainer > form > fieldset > table {
    /* border: solid yellow; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }
  .homeFormContainer > form > fieldset > table > tr {
    /* border: solid purple; */
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 30px;
  }

  .homeFormContainer > form > fieldset > table > tr > td {
    border-bottom: solid grey;
    width: 100%;
    height: 30px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeFormContainer > form > fieldset > table > tr > td > input {
    font-size: 100%;
    width: 90%;
    height: 90%;
    border: none;
    outline: none;
    font-family: "Quicksand", sans-serif;
  }
  .homeFormContainer > form > fieldset > table > button {
    border: solid var(--customColor);
    width: 80%;
    border-radius: 10px;
    font-size: 20px;
    background-color: white;
    color: white;
    background-color: var(--customColor);
    text-align: center;
  }

  .formIcon {
    font-size: 20px;
  }
  .respHomeFormContainer {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .homeFormContainer {
    /* border: solid green; */
    width: 30%;
    height: 100%;
    backdrop-filter: blur(10px);
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    top: 0px;
    position: absolute;
    right: 0px;
    padding: 0px;
  }

  .homeFormContainer > form {
    /* border: solid red; */
    width: 100%;
    height: 100%;
  }
  .homeFormContainer > form > fieldset {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeFormContainer > form > fieldset > legend {
    /* font-size: 20px; */
    text-align: center;
    color: black;
    /* border: solid black; */
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeFormContainer > form > fieldset > table {
    /* border: solid yellow; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
  }
  .homeFormContainer > form > fieldset > table > tr {
    /* border: solid purple; */
    width: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 30px;
  }

  .homeFormContainer > form > fieldset > table > tr > td {
    border-bottom: solid black;
    width: 100%;
    height: 30px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .homeFormContainer > form > fieldset > table > tr > td > input {
    font-size: 100%;
    width: 90%;
    height: 90%;
    border: none;
    outline: none;
  }
  .homeFormContainer > form > fieldset > table > button {
    border: solid var(--customColor);
    width: 70%;
    border-radius: 10px;
    font-size: 20px;
    background-color: white;
    color: white;
    background-color: var(--customColor);
    text-align: center;
  }

  .formIcon {
    font-size: 20px;
  }
  .respHomeFormContainer {
    display: none;
  }
}
