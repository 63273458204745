.partnersContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  background-color: var(--customWhite);
}
.partnerHeadContainer {
  /* border: solid yellowgreen; */
  width: 90%;
}
.partnerHeadContainer > h2 {
  text-align: center;
}
.partnerHeadContainer > h2 > span {
  color: var(--customColor);
}
.partnerMainContainer {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.partnerMainContainer > marquee {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 50vh;
  overflow: hidden;
}

.partnerMainContent {
  border: solid;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.brandIcons {
  /* border: solid; */
  width: 100%;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
