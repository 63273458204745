.testiContainer {
  /* border: solid; */
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  background-color: var(--customWhite);
}
.testiHeadContainer > h2 {
  font-size: 30px;
}
.testiHeadContainer > h2 > span {
  color: var(--customColor);
}
.testiMainContainer {
  /* border: solid green; */
  width: 80%;
}
.testimonialCardContainer {
  /* border: solid greenyellow; */
  width: 90%;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.testiMainContent {
  /* border: solid; */
  margin-right: 10px;
  width: 40%;
}
.testimonialCardContainer > p {
  text-align: justify;
}
.testimonialCardContainer > p > span {
  font-weight: bold;
  text-align: center;
  color: var(--customColor);
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .testiHeadContainer > h2 {
    /* border: solid; */
    width: 100%;
    font-size: 25px;
  }
  .testiContainer {
    /* border: solid; */
    width: 100%;
    height: 100%;
    padding: 20px;
  }
}
