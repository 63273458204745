:root {
  --customColor: #ff2230;
  --customGradient: linear-gradient(to right, #ff2231dd, #fbb13bdd);
  --customTransColor: #ff2231e0;
  --customWhite: #f7f6f7;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "customFont", sans-serif;
}
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

@font-face {
  font-family: "customFont";
  src: url("./Fonts/futura/FUTURA55REGULAR.TTF") format("truetype");
}
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap");

h1,
h2,
h3,
h4,
p,
li {
  font-family: "customFont", sans-serif;
}

li > span {
  font-family: "customFont", sans-serif;
}
