.homeAppContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
  background-color: var(--customWhite);
}

.homeAppWrapper {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.homeAppContent1 {
  /* border: solid blue; */
  width: 50%;
  height: 50vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.homeAppContent2 {
  /* border: solid green; */
  width: 50%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.homeAppContent2 > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.homeAppTxtContainer {
  /* border: solid violet; */
  height: 100%;
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
}
.homeAppHead {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeAppPara {
  /* border: solid green; */
  width: 93%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 10px;
}
.homeAppPara > p {
  font-size: 20px;
  color: var(--customColor);
}
.homeAppParaList {
  /* border: solid; */
  width: 100%;
  margin-top: 10px;
}
.homeAppParaList > ul > li {
  text-align: start;
  font-size: 20px;
  margin: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.homeAppParaList > ul > li > img {
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 320px) and (max-width: 766px) {
  .homeAppContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .homeAppWrapper {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column-reverse;
    gap: 20px;
    height: 100%;
  }
  .homeAppContent1 {
    /* border: solid blue; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;
  }
  .homeAppContent2 {
    /* border: solid green; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .homeAppTxtContainer {
    /* border: solid violet; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
  }
  .homeAppHead {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .appDot {
    /* border: solid green; */
    height: 50%;
    width: 10%;
  }
  .homeAppPara {
    /* border: solid green; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 10px;
  }
  .homeAppHeading {
    /* border: solid; */
    width: 90%;
  }
  .homeAppHeading > h2 {
    font-size: 20px;
  }
  .homeAppPara > p {
    font-size: 20px;
  }
  .homeAppParaList {
    /* border: solid; */
    width: 100%;
    margin-top: 10px;
  }
  .homeAppParaList > li {
    text-align: start;
    list-style-image: url("https://www.bricknbolt.com/assets/images/check-circle.svg");
    font-size: 13px;
    margin: 5px;
  }
}
@media screen and (min-width: 768px) {
  .homeAppContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .homeAppWrapper {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column-reverse;
    gap: 20px;
    height: 100%;
  }
  .homeAppContent1 {
    /* border: solid blue; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .homeAppContent2 {
    /* border: solid green; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .homeAppTxtContainer {
    /* border: solid violet; */
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: column;
  }
  .homeAppHead {
    /* border: solid yellow; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .homeAppHeading {
    /* border: solid; */
    width: 100%;
  }
  .homeAppHeading > h2 {
    font-size: 30px;
    text-align: center;
  }
  .appDot {
    /* border: solid green; */
    height: 100%;
    width: 10%;
  }
  .homeAppPara {
    /* border: solid green; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
  }
  .homeAppPara > p {
    font-size: 20px;
  }
  .homeAppParaList {
    /* border: solid; */
    width: 100%;
    margin-top: 10px;
  }
  .homeAppParaList > li {
    text-align: start;
    list-style-image: url("https://www.bricknbolt.com/assets/images/check-circle.svg");
    font-size: 15px;
    margin: 5px;
  }
}
@media screen and (min-width: 990px) {
  .homeAppContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .homeAppWrapper {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    height: 100%;
  }
  .homeAppContent1 {
    /* border: solid blue; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .homeAppContent2 {
    /* border: solid green; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .homeAppTxtContainer {
    /* border: solid violet; */
    height: 100%;
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .homeAppHead {
    /* border: solid yellow; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .homeAppHeading {
    /* border: solid; */
    width: 100%;
  }
  .homeAppHeading > h2 {
    font-size: 30px;
    text-align: start;
  }
  .appDot {
    /* border: solid green; */
    height: 100%;
    width: 10%;
  }
  .homeAppPara {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
  }
  .homeAppPara > p {
    font-size: 20px;
  }
  .homeAppParaList {
    /* border: solid; */
    width: 100%;
    margin-top: 10px;
  }
  .homeAppParaList > li {
    text-align: start;
    list-style-image: url("https://www.bricknbolt.com/assets/images/check-circle.svg");
    font-size: 15px;
    margin: 5px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .homeAppContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .homeAppWrapper {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    gap: 20px;
    height: 100%;
  }
  .homeAppContent1 {
    /* border: solid blue; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .homeAppContent2 {
    /* border: solid green; */
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeAppContent2 > img {
    width: 100%;
    height: 70%;
    object-fit: contain;
  }
  .homeAppTxtContainer {
    /* border: solid violet; */
    height: 100%;
    width: 85%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .homeAppHead {
    /* border: solid yellow; */
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .homeAppHeading {
    /* border: solid; */
    width: 100%;
  }
  .homeAppHeading > h2 {
    text-align: start;
    font-size: 30px;
  }

  .homeAppPara {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
  }
  .homeAppPara > p {
    font-size: 20px;
  }
  .homeAppParaList {
    /* border: solid; */
    width: 100%;
    margin-top: 10px;
  }
  .homeAppParaList > li {
    text-align: start;
    list-style-image: url("https://www.bricknbolt.com/assets/images/check-circle.svg");
    font-size: 15px;
    margin: 5px;
  }
}
