.brandWeUseContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 10px;
}
.brandWeUseHeadContainer > h2 {
  font-size: 30px;
}

.brandWeUseHeadContainer > h2 > span {
  color: var(--customColor);
}
.brandMainContainer {
  /* border: solid blue; */
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.brandMainContent1 {
  /* border: solid brown; */
}
.brandMainContent1 > img {
  height: 300px;
  width: 100%;
}
.brandMainContent2 {
  /* border: solid; */
  height: 300px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--customColor);
}
.brandMainContent2 > p {
  width: 100%;
  font-size: 20px;
  padding: 20px;
  color: white;
  text-align: justify;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .brandMainContainer {
    /* border: solid blue; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .brandMainContent2 {
    /* border: solid; */
    min-width: 300px;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .brandMainContainer {
    /* border: solid blue; */
    width: 100%;
    /* height: 20vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .brandMainContent1 > img {
    width: 100%;
  }
  .brandMainContent2 {
    /* border: solid; */
    height: 40vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--customColor);
  }
}
@media screen and (min-width: 990px) and (max-width: 1200px) {
  .brandWeUseContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin-top: 50px;
  }

  .brandMainContainer {
    /* border: solid blue; */
    width: 60%;
    /* height: 20vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brandMainContent1 {
    /* border: solid brown; */
  }
  .brandMainContent1 > img {
    height: 50vh;
  }
  .brandMainContent2 {
    /* border: solid; */
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--customColor);
  }
  .brandMainContent2 > p {
    width: 90%;
    font-size: 20px;
    color: white;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .brandWeUseContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 10px;
  }

  .brandMainContainer {
    /* border: solid blue; */
    width: 60%;
    /* height: 20vh; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .brandMainContent1 {
    /* border: solid brown; */
  }
  .brandMainContent1 > img {
    height: 50vh;
  }
  .brandMainContent2 {
    /* border: solid; */
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--customColor);
  }
  .brandMainContent2 > p {
    width: 90%;
    font-size: 20px;
    color: white;
  }
}
