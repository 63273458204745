.waterProofChoose {
    /* border: solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    padding: 50px !important;
    padding-top: 0 !important;
  }
  .waterProofChoose > h1 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
  }
  .waterProofChoose > h2 {
    color: var(--customColor);
    width: 100%;
    text-align: center;
    font-size: 25px;
  }
  .waterProofChoose > h3 {
    color: var(--customColor);
    width: 100%;
    text-align: start;
    font-size: 25px;
  }
  .waterProofChoose > h6 {
    color: var(--customColor);
    width: 100%;
    text-align: start;
    font-size: 18px;
  }
  .waterProofChoose > p {
    width: 100%;
    font-size: 20px;
    text-align: justify;
  }
  .waterProofChoose > p > a {
    color: var(--customColor);
    text-decoration: none;
  }
  .waterProofChoose > li > h6 {
    color: var(--customColor);
    font-size: 20px;
  }
  .waterProofChoose > li > p > a {
    color: var(--customColor);
    text-decoration: none;
  }
  .waterProofChoose > li {
    /* border: solid; */
    width: 100%;
    font-size: 20px;
    text-align: start;
    list-style: none;
  }
  .waterProofChoose > li > span {
    /* border: solid; */
    color: var(--customColor);
  }
  .waterProofChoose > li > a {
    text-decoration: none;
    color: var(--customColor);
  }
  .waterProofChoose {
    /* border: solid red; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 30px;
  }
  .waterProofChoose > h5 {
    font-size: 25px;
    color: var(--customColor);
    text-align: center;
    width: 100%;
  }
  .waterProofChoose > p {
    font-size: 20px;
    width: 100%;
    text-align: justify;
  }
  .waterProofChoose > p > a {
    color: var(--customColor);
    text-decoration: none;
  }
  .waterProofChoose > li > ul > li > p > a {
    text-decoration: none;
    color: var(--customColor);
  }
  .waterProofChoose > li > ul > li {
    list-style: none;
    font-size: 20px;
    text-align: justify;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 90%;
  }
  .waterProofChoose > li > ul > li > span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--customColor);
    text-align: start;
  }
  .waterProofChoose > li > ul > li > span > img {
    width: 20px;
    height: 20px;
  }
  
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .waterProofChoose > h2 {
      color: var(--customColor);
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
    .waterProofChoose > h3 {
      color: var(--customColor);
      width: 100%;
      text-align: start;
      font-size: 20px;
    }
    .waterProofChoose {
      /* border: solid red; */
      padding: 5px;
    }
  }
  