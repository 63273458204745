.waterProofingWhy {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 50px;
  padding-top: 0;
}
.waterProofingWhy > h5 {
  font-size: 25px;
  color: var(--customColor);
  text-align: start;
  width: 100%;
}
.waterProofingWhy > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.waterProofingWhy > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.waterProofingWhy > ul > li > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.waterProofingWhy > ul > li {
  /* border: solid; */
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.waterProofingWhy > ul > h4 {
  list-style: none;
  font-size: 22px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
  color: var(--customColor);
}
.waterProofingWhy > ul > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.waterProofingWhy > ul > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.waterProofingWhy > ul > li > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: var(--customColor);
  text-align: start;
}
.waterProofingWhy > ul > li > span > img {
  width: 20px;
  height: 20px;
}
.waterProofingWhy > ul > li > ul > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}
.waterProofingWhy > ul > li > ul > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--customColor);
  text-align: start;
}
.waterProofingWhy > ul > li > ul > li > span > img {
  width: 15px;
  height: 15px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .waterProofingWhy {
    /* border: solid red; */
    padding: 5px;
  }
  .waterProofingWhy > ul {
    margin: 0;
    padding: 0;
  }
  .waterProofingWhy > ul > li {
    margin: 0px;
    padding: 10px;
  }
}
