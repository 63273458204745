.faqContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
}
.faqHeaderContainer > h2 {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  letter-spacing: 2px;
}
.faqHeaderContainer > h2 > span {
  color: var(--customColor);
}
.faqHeaderContainer {
  /* border: solid red; */
  width: 90%;
}
.faqMainContainer {
  /* border: solid blue; */
  width: 60%;
  gap: 20px;
}
.faqContent {
  /* border: solid green; */
}
.faqItem {
  /* border-bottom: solid grey; */
}
.active > {
  /* border: solid; */
  width: 100%;
  position: absolute;
  background-color: var(--customColor);
}
.faqHead {
  /* border: solid green; */
  height: 8vh;
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
  border-radius: 20px;
  box-shadow: 0px 3px 10px 1px rgb(206, 204, 204);
  color: var(--customColor);
}
.faqHead:hover {
  background-color: var(--customColor);
  color: white;
}
.faqPanel {
  box-shadow: 0px 3px 10px 1px rgb(206, 204, 204);
  margin-top: 20px;
  border-radius: 10px;
  background-color: white;
  padding: 10px;
}
.faqPanel > p {
  font-size: 20px;
  margin-left: 20px;
  margin: 10px;
}
.faqBut {
  /* border: solid purple; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.faqBut > h3 {
  margin-left: 20px;
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .faqContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .faqHeaderContainer {
    /* border: solid red; */
    width: 90%;
  }
  .faqMainContainer {
    /* border: solid blue; */
    width: 90%;
    gap: 20px;
  }
  .faqContent {
    /* border: solid green; */
  }
  .faqItem {
    /* border-bottom: solid grey; */
  }
  .faqHead {
    /* border: solid green; */
    height: 100%;
    min-width: 300px;
    margin-top: 40px;
    cursor: pointer;
    padding: 10px;
  }
  .faqPanel > p {
    font-size: 17px;
  }
  .faqBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .faqBut > h3 {
    margin-left: 20px;
    font-size: 17px;
  }
}
@media screen and (min-width: 768px) {
  .faqContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .faqHeaderContainer {
    /* border: solid red; */
    width: 90%;
  }
  .faqMainContainer {
    /* border: solid blue; */
    width: 90%;
    gap: 20px;
  }
  .faqContent {
    /* border: solid green; */
  }
  .faqItem {
    /* border-bottom: solid grey; */
  }
  .faqHead {
    /* border: solid green; */
    height: 10vh;
    margin-top: 40px;
    cursor: pointer;
  }
  .faqPanel > p {
    font-size: 17px;
  }
  .faqBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

@media screen and (min-width: 990px) {
  .faqContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .faqHeaderContainer {
    /* border: solid red; */
    width: 90%;
  }
  .faqMainContainer {
    /* border: solid blue; */
    width: 60%;
    gap: 20px;
  }

  .faqHead {
    /* border: solid green; */
    height: 10vh;
    margin-top: 40px;
    cursor: pointer;
  }
  .faqPanel > p {
    font-size: 20px;
  }
  .faqBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .faqContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .faqHeaderContainer {
    /* border: solid red; */
    width: 90%;
  }
  .faqMainContainer {
    /* border: solid blue; */
    width: 60%;
    gap: 20px;
  }
  .faqContent {
    /* border: solid green; */
  }
  .faqItem {
    /* border-bottom: solid grey; */
  }
  .faqHead {
    /* border: solid green; */
    height: 8vh;
    margin-top: 40px;
    cursor: pointer;
  }
  .faqPanel > p {
    font-size: 20px;
  }
  .faqBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
