.locationWrapper {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}
.locationHeadContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}
.locationHeadContainer > h1 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
}
.locationHeadContainer > h2 {
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.locationHeadContainer > h2 > span {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}

.locationHeadContainer > h3 {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.locationHeadContainer > h4 {
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.locationHeadContainer > h5 {
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.locationHeadContainer > h5 > span,
.locationHeadContainer > h4 > span {
  color: var(--customColor);
}
.locationHeadContainer > li {
  list-style: none;
  margin: 10px;
  font-size: 20px;
  text-align: justify;
}
.locationHeadContainer > li > h6 {
  font-size: 20px;
  text-align: justify;
  color: var(--customColor);
}
.locationHeadContainer > li > a {
  text-decoration: none;
  color: var(--customColor);
}

.locationHeadContainer > li > span {
  color: var(--customColor);
  font-weight: bold;
}
.locationHeadContainer > li > ul > li {
  list-style: none;
  margin: 10px;
}
.locationHeadContainer > li > ul > li > span {
  color: var(--customColor);
  font-weight: bold;
}
.locationHeadContainer > li > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}

.locationHeadContainer > p {
  text-align: justify;
  font-size: 20px;
}
.locationHeadContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
/* --------------------ReadMore---------------------------------------------- */
.locationReadContainer {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}
.locationReadContainer > p {
  text-align: justify;
  font-size: 20px;
}
.locationReadContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.locationReadDisplayContainer {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: none;
  cursor: default;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.locationReadContainer > button {
  border: solid red;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--customColor);
  color: white;
  font-size: 20px;
}

/* ------------------------------------------------------------------ */

.locationHeadContainer > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.locationHeadContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.locationHeadContainer > li > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.locationHeadContainer > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}
.locationHeadContainer > ul {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}
.locationHeadContainer > ul > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}
.locationHeadContainer > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: start;
}
.locationHeadContainer > ul > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--customColor);
  text-align: start;
}
.locationHeadContainer > li > span > img {
  width: 20px;
  height: 20px;
  font-weight: bold;
}
.locationHeadContainer > ul > li > span > img {
  width: 20px;
  height: 20px;
  font-weight: bold;
}
/* --------------------------Testimonial---------------------------------- */
.locationTestimonial {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 100%;
}
.locationTestimonialContent {
  /* border: solid blue; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  width: 100%;
}
.locationTestimonial > h2 {
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.locationTestimonial > h2 > span {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.locationTestimonial > p {
  text-align: justify;
  font-size: 20px;
}
.locationTestimonial > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.locationTestimonialItem {
  /* border: solid green; */
  width: 400px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--customWhite);
}
.locationTestimonialItem > p {
  font-size: 20px;
  text-align: justify;
}
.locationTestimonialItem > p > span {
  color: var(--customColor);
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .locationTestimonialContent {
    /* border: solid blue; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .locationWrapper {
    /* border: solid; */
    /* height: 70vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }
  .locationHeadContainer {
    /* border: solid red; */
    padding: 20px;
  }
  .locationHeadContainer > h2 {
    text-align: center;
    font-size: 20px;
  }
  .locationHeadContainer > h3 {
    text-align: center;
    font-size: 20px;
  }
  .locationHeadContainer > h4 {
    text-align: center;
    font-size: 20px;
  }
  .locationHeadContainer > h5 {
    text-align: center;
    font-size: 20px;
  }
  .locationReadContainer {
    /* border: solid red; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    text-align: justify;
    padding: 10px;
  }

  .locationReadDisplayContainer {
    height: 100%;
  }
  .locationHeadContainer {
    /* border: solid red; */
    padding: 5px;
  }
}
