.interiorWrapper {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}
.interiorHeadContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}
.interiorHeadContainer > h1 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
}
.interiorHeadContainer > h2 {
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.interiorHeadContainer > h2 > span {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}

.interiorHeadContainer > h3 {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.interiorHeadContainer > h4 {
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.interiorHeadContainer > h5 {
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.interiorHeadContainer > h5 > span,
.interiorHeadContainer > h4 > span {
  color: var(--customColor);
}
/* .interiorHeadContainer > li {
  list-style: none;
  margin: 10px;
  font-size: 20px;
  text-align: justify;
} */
.interiorHeadContainer > li > h6 {
  font-size: 20px;
  text-align: justify;
  color: var(--customColor);
}
.interiorHeadContainer > li > a {
  text-decoration: none;
  color: var(--customColor);
}

/* .interiorHeadContainer > li > span {
  color: var(--customColor);
  font-weight: bold;
} */
.interiorHeadContainer > li > ul > li {
  list-style: none;
  margin: 10px;
}
.interiorHeadContainer > li > ul > li > span {
  color: var(--customColor);
  font-weight: bold;
}
.interiorHeadContainer > li > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}

/* .interiorHeadContainer > p {
  text-align: justify;
  font-size: 20px;
} */
.interiorHeadContainer > p {
  font-size: 20px;
  width: 100%;
  text-align: justify;
}
.interiorHeadContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.interiorHeadContainer > li > p > a {
  text-decoration: none;
  color: var(--customColor);
}
.interiorHeadContainer > li {
  list-style: none;
  font-size: 20px;
  text-align: justify;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 90%;
}
.interiorHeadContainer > li > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--customColor);
  text-align: start;
  font-weight: bold;

}
.interiorHeadContainer > li > span > img {
  width: 20px;
  height: 20px;
  font-weight: bold;
}
/* ----------------------------interior Readmore----------------------------------- */
.interiorReadContainer {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}

.interiorReadDisplayContainer {
  /* border: solid green; */
  width: 100%;
  height: 100%;
  display: none;
  cursor: default;
  display: none;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.interiorReadContainer > button {
  border: solid red;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--customColor);
  color: white;
  font-size: 20px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .interiorWrapper {
    /* border: solid; */
    /* height: 70vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }
  .interiorHeadContainer {
    /* border: solid red; */
    padding: 20px;
  }
  .interiorHeadContainer > h2 {
    text-align: center;
    font-size: 20px;
  }
  .interiorHeadContainer > h3 {
    text-align: center;
    font-size: 20px;
  }
  .interiorHeadContainer > h4 {
    text-align: center;
    font-size: 20px;
  }
  .interiorHeadContainer > h5 {
    text-align: center;
    font-size: 20px;
  }
  /* -------------------interior Readmore------------------------------------- */
  .interiorReadContainer {
    /* border: solid red; */
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 0px;
    text-align: justify;
    padding: 10px;
  }

  .interiorReadDisplayContainer {
    height: 100%;
  }
}
