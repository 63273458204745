.hebbalTestimonial {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  width: 100%;
}
.hebbalTestimonialContent {
  /* border: solid blue; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  width: 100%;
}
.hebbalTestimonial > h2 {
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.hebbalTestimonial > h2 > span {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.hebbalTestimonial > p {
  text-align: justify;
  font-size: 20px;
}
.hebbalTestimonial > p > a {
  color: var(--customColor);
  text-decoration: none;
}
.hebbalTestimonialItem {
  /* border: solid green; */
  width: 400px;
  padding: 20px;
  border-radius: 20px;
  background-color: var(--customWhite);
}
.hebbalTestimonialItem > p {
  font-size: 20px;
  text-align: justify;
}
.hebbalTestimonialItem > p > span {
  color: var(--customColor);
  font-weight: bold;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .hebbalTestimonialContent {
    /* border: solid blue; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    flex-direction: column;
  }
}
