.packageContainer {
  /* border: solid red; */
  margin-top: 50px;
}
.packageHeadContainer > h2 {
  font-size: 30px;
  text-align: center;
}
.packageHeadContainer > h2 > span {
  color: var(--customColor);
}
.packageMenuContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.menuCardContainer > a {
  width: 100%;
  height: 100%;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: black;
  gap: 20px;
  margin: 20px;
}
.menuCardContainer {
  /* border: solid blue; */
  width: 17%;
  height: 177px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0px 0px 5px black;
  transition: 200ms ease-in-out;
  margin: 20px;
}
.head2 {
  letter-spacing: 2px;
}
.menuCardContainer > a > h3 {
  font-size: 25px;
}
.menuCardContainer > a > h2 {
  font-size: 30px;
}
.menuCardContainer:hover {
  background-color: var(--customColor);
}
.menuCardContainer:hover .head2 {
  color: white;
}
.menuCardContainer:hover .head1 {
  color: white;
}

.packageListContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.packageListCardContainer {
  border: 2px solid black;
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  gap: 20px;
  margin: 20px;
}
.packageListCardHead {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.packageListCardHead > h3 {
  color: var(--customColor);
}
.packageListCardHead > h2 {
  color: var(--customColor);
}
.packageListCardMain {
  /* border: 2px solid black; */
  width: 100%;
  height: 100%;
}
/* .packContent {
    border: solid red;
    width: 100%;height: 100%;
  } */
.packItem {
  border-bottom: 2px solid grey;
  height: 100%;
  margin: 10px;
}
/* .packHead {
  border: solid green;
  height: 100%;
  margin-top: 40px;
  cursor: pointer;
} */
.packPanel {
  border: solid transparent;
  background-color: var(--customWhite);
  height: 100%;
}
.packPanel > p {
  font-size: 20px;
  background-color: var(--customWhite);
}
.packPanel > p > ul > li {
  margin: 20px;
}
.packBut {
  /* border: solid purple; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.packBut > h3 {
  color: black;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .packageHeadContainer {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .packageMenuContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 30px;
  }
  .menuCardContainer > a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    gap: 20px;
    margin: 20px;
  }
  .menuCardContainer {
    /* border: solid blue; */
    width: 80%;
    height: 177px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 5px black;
    transition: 200ms ease-in-out;
    margin: 20px;
  }
  .head2 {
    letter-spacing: 2px;
  }
  .menuCardContainer:hover {
    background-color: var(--customColor);
  }
  .menuCardContainer:hover .head2 {
    color: white;
  }
  .menuCardContainer:hover .head1 {
    color: white;
  }
  .packageDisplayContainer {
    /* border: solid blue; */
  }
  .packageListContainer {
    /* border: solid red; */
    width: 100%;
    display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .packageListCardContainer {
    /* border: solid; */
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
  }
  .packageListCardHead {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .packageListCardMain {
    /* border: 2px solid black; */
    width: 100%;
    border-radius: 10px;
    height: 100%;
  }
  /* .packContent {
      border: solid red;
      width: 100%;height: 100%;
    } */
  .packItem {
    border-bottom: 2px solid grey;
    height: 100%;
    margin: 10px;
    cursor: pointer;
  }
  /* .packHead {
    border: solid green;
    height: 100%;
    margin-top: 40px;
    cursor: pointer;
  } */
  .packPanel {
    border: solid transparent;
    background-color: var(--customWhite);
    height: 100%;
  }
  .packPanel > p {
    font-size: 20px;
    background-color: var(--customWhite);
  }
  .packPanel > p > ul > li {
    margin: 20px;
  }
  .packBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-width: 768px) {
  .packageContainer {
    /* border: solid red; */
  }
  .packageHeadContainer {
    /* border: solid green; */
  }

  .packageMenuContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .menuCardContainer > a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    gap: 20px;
    margin: 20px;
  }
  .menuCardContainer {
    /* border: solid blue; */
    width: 40%;
    height: 177px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 5px black;
    transition: 200ms ease-in-out;
    margin: 20px;
  }
  .head2 {
    letter-spacing: 2px;
  }
  .menuCardContainer:hover {
    background-color: var(--customColor);
  }
  .menuCardContainer:hover .head2 {
    color: white;
  }
  .menuCardContainer:hover .head1 {
    color: white;
  }
  .packageDisplayContainer {
    /* border: solid blue; */
  }
  .packageListContainer {
    /* border: solid red; */
    width: 100%;
    display: none;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
  }
  .packageListCardContainer {
    /* border: solid; */
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
  }
  .packageListCardHead {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .packageListCardMain {
    /* border: 2px solid black; */
    width: 100%;
    border-radius: 10px;
    height: 100%;
  }
  /* .packContent {
      border: solid red;
      width: 100%;height: 100%;
    } */
  .packItem {
    border-bottom: 2px solid grey;
    height: 100%;
    margin: 10px;
  }
  /* .packHead {
    border: solid green;
    height: 100%;
    margin-top: 40px;
    cursor: pointer;
  } */
  .packPanel {
    border: solid transparent;
    background-color: var(--customWhite);
    height: 100%;
  }
  .packPanel > p {
    font-size: 20px;
    background-color: var(--customWhite);
  }
  .packPanel > p > ul > li {
    margin: 20px;
  }
  .packBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-width: 990px) {
  .packageContainer {
    /* border: solid red; */
  }
  .packageHeadContainer {
    /* border: solid green; */
  }

  .packageMenuContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .menuCardContainer > a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    gap: 20px;
    margin: 20px;
  }
  .menuCardContainer {
    /* border: solid blue; */
    width: 300px;
    height: 177px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 5px black;
    transition: 200ms ease-in-out;
    margin: 20px;
  }
  .head1 {
    font-size: 20px;
  }
  .head2 {
    letter-spacing: 2px;
  }
  .menuCardContainer:hover {
    background-color: var(--customColor);
  }
  .menuCardContainer:hover .head2 {
    color: white;
  }
  .menuCardContainer:hover .head1 {
    color: white;
  }
  .packageDisplayContainer {
    /* border: solid blue; */
  }
  .packageListContainer {
    /* border: solid red; */
    width: 100%;
    display: none;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .packageListCardContainer {
    /* border: solid; */
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
  }
  .packageListCardHead {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .packageListCardMain {
    /* border: 2px solid black; */
    width: 100%;
    border-radius: 10px;
    height: 100%;
  }
  /* .packContent {
      border: solid red;
      width: 100%;height: 100%;
    } */
  .packItem {
    border-bottom: 2px solid grey;
    height: 100%;
    margin: 10px;
  }
  /* .packHead {
    border: solid green;
    height: 100%;
    margin-top: 40px;
    cursor: pointer;
  } */
  .packPanel {
    border: solid transparent;
    background-color: var(--customWhite);
    height: 100%;
  }
  .packPanel > p {
    font-size: 20px;
    background-color: var(--customWhite);
  }
  .packPanel > p > ul > li {
    margin: 20px;
  }
  .packBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
@media screen and (min-width: 1200px) {
  .packageContainer {
    /* border: solid red; */
  }
  .packageHeadContainer {
    /* border: solid green; */
  }

  .packageMenuContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .menuCardContainer > a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: black;
    gap: 20px;
    margin: 20px;
  }
  .menuCardContainer {
    /* border: solid blue; */
    width: 300px;
    height: 177px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 0px 5px black;
    transition: 200ms ease-in-out;
    margin: 20px;
  }
  .head1 {
    font-size: 30px;
  }
  .head2 {
    letter-spacing: 2px;
  }

  .packageListContainer {
    /* border: solid red; */
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .packageListCardContainer {
    /* border: solid; */
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
  }
  .packageListCardHead {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .packageListCardMain {
    /* border: 2px solid black; */
    width: 100%;
    /* border-radius: 10px; */
    height: 100%;
  }
  /* .packContent {
      border: solid red;
      width: 100%;height: 100%;
    } */
  .packItem {
    border-bottom: 2px solid grey;
    height: 100%;
    margin: 10px;
  }
  /* .packHead {
    border: solid green;
    height: 100%;
    margin-top: 40px;
    cursor: pointer;
  } */
  .packPanel {
    border: solid transparent;
    background-color: var(--customWhite);
    height: 100%;
  }
  .packPanel > p {
    font-size: 20px;
    background-color: var(--customWhite);
  }
  .packPanel > p > ul > li {
    margin: 20px;
  }
  .packBut {
    /* border: solid purple; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
