.HomeConstructionImg {
  /* border: solid red; */
  width: 100%;
  margin-top: 50px;
  padding: 30px;
}
.HomeConstructionImg > a {
  width: 100%;
  margin: 0;
}
.HomeConstructionImg > a > img {
  /* border: solid blue; */
  width: 100%;
  height: 100%;
}
.HomInteriorImg {
  /* border: solid red; */
  width: 100%;
  margin-top: 50px;
  padding: 30px;
}
.HomInteriorImg > a {
  width: 100%;
  margin: 0;
}
.HomInteriorImg > a > img {
  /* border: solid blue; */
  width: 100%;
  height: 100%;
}
