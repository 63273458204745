.ourGoalContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}
.ourGoalHead {
  /* border: solid blue; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ourGoalHead > h2{
  font-size: 30px;
}
.ourGoalHead > h2 > span {
  color: var(--customColor);
}
.ourGoalMainContainer {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.ourGoalCardContainer {
  /* border: solid teal; */
  width: 20%;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 10px;
}
.ourGoalCardHead {
  /* border: solid red; */
  text-align: center;
}
.ourGoalCardContent {
  border: solid var(--customColor);
  height: 39vh;
  width: 90%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ourGoalCardList {
  /* border: solid blue; */
  /* border-radius: 50%; */
  height: 100%;
  width: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ourGoalCardList > ul {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ourGoalCardList > ul > li {
  font-size: 17px;
  text-align: start;
  list-style: none;
}
.ourGoalCardContent:hover .ourGoalCardList > ul > li {
  color: white;
}
.ourGoalCardContent:hover {
  background-color: var(--customColor);
}
@media screen and (min-width: 320px) {
  .ourGoalMainContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
  }
  .ourGoalCardContainer {
    /* border: solid teal; */
    min-width: 320px;
    height: 100%;
    margin: 0;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
    padding: 25px;

  }
  .ourGoalCardContent {
    border: solid var(--customColor);
    height: 270px;
    width: 100%;
    padding: 20px;
  }
}
@media screen and (min-width: 768px) {
  .ourGoalMainContainer {
    /* border: solid green; */
    min-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 30px;
  }
  .ourGoalCardContainer {
    /* border: solid teal; */
    min-width: 320px;
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .ourGoalCardContent {
    border: solid var(--customColor);
    height: 45vh;
    width: 100%;
    padding: 20px;
  }
}
@media screen and (min-width: 990px) {
  .ourGoalMainContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
  }
  .ourGoalCardContainer {
    /* border: solid teal; */
    min-width: 350px;
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .ourGoalCardContent {
    border: solid var(--customColor);
    height: 36vh;
    width: 100%;
    padding: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .ourGoalMainContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
  }
  .ourGoalCardContainer {
    /* border: solid teal; */
    min-width: 350px;
    margin: 0;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
  }
  .ourGoalCardContent {
    border: solid var(--customColor);
    height: 300px;
    width: 100%;
    padding: 20px;
  }
  .ourGoalCardList > ul > li {
    font-size: 18px;
  }
}
