.homeHeaderImgContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.homeHeaderImgContainer > img {
  width: 100%;
  height: 70vh;
}
.homeHeadContainer {
  /* border: solid; */
  width: 100%;
}
.homeHeadingContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 10px;
}
.homeHeadingContent {
  /* border: solid; */
  width: 80%;
}
.homeHeaditem2 {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeHeaditem2 > p {
  font-size: 20px;
  text-align: start;
  width: 95%;
}
.homeHeaditem1 {
  /* border: solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}


.homeHeaderImgTxt {
  /* border: solid red; */
  position: absolute;
  z-index: 1;
  height: 80%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(10px);
  gap: 20px;
}
.homeHeaderImgTxtContainer {
  /* border: solid blue; */
  background-color: white;
}

.homeHeaderImgTxtPara {
  /* border: solid; */
  width: 90%;
}
.homeHeaderImgTxtPara {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.homeHeaderImgTxtItem {
  border-right: solid white;
  height: 30px;
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.homeHeaderImgTxtItem > P {
  font-size: 20px;
  color: white;
  text-align: start;
}
.homeHeaderImgTxtWel {
  /* border: solid; */
  width: 100%;
}
.homeHeaderImgTxtWel > p {
  font-size: 25px;
  color: white;
  text-align: center;
}
.homeHeaderImgTxtWel > p > span {
  color: var(--customColor);
}
@media screen and (min-width: 320px) {
  .homeHeaderContainer {
    /* border: solid; */
    width: 100%;
  }
  .homeHeaderImgContainer {
    /* border: solid; */
    width: 100%;
    height: 100%;
  }
  .homeHeaderImgContainer > img {
    width: 100%;
    height: 100%;
    display: none;
  }
  .homeHeadContainer {
    /* border: solid; */
    width: 100%;
  }
  .respHomeHeaderImgContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .respHomeHeaderImgContainer > img {
    width: 100%;
    /* border: solid; */
    height: 100%;
  }
  .homeHeadingContainer {
    /* border: solid; */
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 10px;
  }
  .homeHeadingContent {
    /* border: solid; */
    width: 95%;
  }
  .homeHeaditem2 {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeHeaditem2 > p {
    font-size: 18px;
    text-align: start;
    width: 75%;
  }
  .homeHeaditem1 {
    /* border: solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  .homeHeadingInnerItem2 {
    /* border: solid; */
    width: 90%;
  }

  .homeHeadingInnerItem1 {
    /* border: solid teal; */
    width: 10%;
    height: 100%;
  }
  .dot {
    /* border: solid; */
    width: 20px;
    height: 20px;
    background-color: var(--customColor);
    transform: skew(-40deg);
  }
  .homeHeaderImgTxt {
    /* border: solid red; */
    position: static;
    z-index: 1;
    height: 100vh;
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    gap: 20px;
    top: -100px;
  }
  .homeHeaderImgTxtContainer {
    /* border: solid blue; */
    background-color: white;
  }

  .homeHeaderImgTxtPara {
    /* border: solid; */
    width: 90%;
  }
  .homeHeaderImgTxtPara {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .homeHeaderImgTxtItem {
    border-right: solid white;
    height: 30px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeHeaderImgTxtItem > P {
    font-size: 20px;
    color: white;
    text-align: start;
  }
  .homeHeaderImgTxtWel {
    /* border: solid; */
    width: 100%;
  }
  .homeHeaderImgTxtWel > p {
    font-size: 25px;
    color: white;
    text-align: center;
  }
}
@media screen and (min-width: 768px) {
  .homeHeaderContainer {
    /* border: solid; */
    width: 100%;
  }
  .homeHeaderImgContainer {
    width: 100%;
    height: 50vh;
  }
  .homeHeaderImgContainer > img {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .respHomeHeaderImgContainer {
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .homeHeadContainer {
    /* border: solid; */
    width: 100%;
  }
  .homeHeadingContainer {
    /* border: solid; */
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 10px;
  }
  .homeHeadingContent {
    /* border: solid; */
    width: 95%;
  }
  .homeHeaditem2 {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeHeaditem2 > p {
    font-size: 18px;
    text-align: start;
    width: 75%;
  }
  .homeHeaditem1 {
    /* border: solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }
  .homeHeadingInnerItem2 {
    /* border: solid; */
    width: 90%;
  }

  .homeHeadingInnerItem1 {
    /* border: solid teal; */
    width: 10%;
    height: 100%;
  }
  .dot {
    /* border: solid; */
    width: 20px;
    height: 20px;
    background-color: var(--customColor);
    transform: skew(-40deg);
  }
  .homeHeaderImgTxt {
    /* border: solid red; */
    position: absolute;
    z-index: 1;
    height: 80%;
    width: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    gap: 20px;
  }
}
@media screen and (min-width: 990px) {
  .homeHeaderContainer {
    /* border: solid; */
    width: 100%;
  }
  .homeHeaderImgContainer {
    width: 100%;
    height: 55vh;
  }
  .homeHeaderImgContainer > img {
    width: 100%;
    height: 100%;
  }
  .homeHeadContainer {
    /* border: solid; */
    width: 100%;
  }
  .homeHeadingContainer {
    /* border: solid; */
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 10px;
  }
  .homeHeadingContent {
    /* border: solid; */
    width: 90%;
  }

  .homeHeaditem2 {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeHeaditem2 > p {
    font-size: 20px;
    text-align: start;
    width: 88%;
  }
  .homeHeaditem1 {
    /* border: solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .homeHeadingInnerItem1 {
    /* border: solid teal; */
    width: 5%;
    height: 100%;
  }
  .dot {
    /* border: solid; */
    width: 20px;
    height: 20px;
    background-color: var(--customColor);
    transform: skew(-40deg);
  }
  .homeHeaderImgTxt {
    /* border: solid red; */
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(10px);
    top: 0px;
    gap: 20px;
  }
  .homeHeaderImgTxtContainer {
    /* border: solid blue; */
    background-color: white;
  }
  .homeHeaderImgTxtContainer > img {
    height: 90px;
  }

  .homeHeaderImgTxtPara {
    /* border: solid; */
    width: 90%;
  }
  .homeHeaderImgTxtPara {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .homeHeaderImgTxtItem {
    border-right: solid white;
    height: 30px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeHeaderImgTxtItem > P {
    font-size: 15px;
    color: white;
    text-align: start;
  }
  .homeHeaderImgTxtWel {
    /* border: solid; */
    width: 100%;
  }
  .homeHeaderImgTxtWel > p {
    font-size: 20px;
    color: white;
    text-align: center;
  }
}
@media screen and (min-width: 1200px) {
  .homeHeaderContainer {
    width: 100%;
    /* border: solid; */
  }
  .homeHeaderImgContainer {
    /* border: solid; */
    width: 100%;
    height: 100%;
  }
  .homeHeaderImgContainer > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .homeHeadingContainer {
    /* border: solid; */
    width: 100%;
    display: none;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 10px;
  }
  .homeHeadingContent {
    /* border: solid; */
    width: 90%;
  }
  .homeHeaditem2 {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeHeaditem2 > p {
    font-size: 20px;
    text-align: start;
    width: 88%;
  }
  .homeHeaditem1 {
    /* border: solid red; */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .homeHeaderImgTxt {
    /* border: solid red; */
    position: absolute;
    z-index: 1;
    height: 80%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    top: 50px;
    gap: 20px;
  }
  .homeHeaderImgTxtContainer {
    /* border: solid blue; */
    background-color: white;
  }

  .homeHeaderImgTxtPara {
    /* border: solid; */
    width: 90%;
  }
  .homeHeaderImgTxtPara {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .homeHeaderImgTxtItem {
    border-right: solid white;
    height: 30px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .homeHeaderImgTxtItem > P {
    font-size: 20px;
    color: white;
    text-align: start;
  }
  .homeHeaderImgTxtWel {
    /* border: solid; */
    width: 100%;
  }
  .homeHeaderImgTxtWel > p {
    font-size: 25px;
    color: white;
    text-align: center;
  }
}
