.welcomeContainer {
  /* border: solid; */
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10px;
}
.welcomeContainer > h1 {
  font-size: 30px;
  text-align: center;
}
.welcomeContainer > h1 > span {
  color: var(--customColor);
}
.welcomeContainer > p {
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
}
.welcomeContainer > p > span {
  color: var(--customColor);
}
@media screen and (min-width: 320px) {
  .welcomeContainer {
    /* border: solid; */
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }
  .welcomeContainer > h1 {
    text-align: center;
    font-size: 20px;
  }
  .welcomeContainer > p {
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .welcomeContainer {
    /* border: solid; */
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }
  .welcomeContainer > h1 {
    text-align: center;
    font-size: 30px;
  }
  .welcomeContainer > p {
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .welcomeContainer {
    /* border: solid; */
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }
  .welcomeContainer > h1 {
    text-align: center;
    font-size: 30px;
  }
  .welcomeContainer > p {
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 768px) {
  .welcomeContainer {
    /* border: solid; */
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }
  .welcomeContainer > h1 {
    text-align: center;
    font-size: 30px;
  }
  .welcomeContainer > p {
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 990px) {
  .welcomeContainer {
    /* border: solid; */
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }
  .welcomeContainer > h1 {
    text-align: center;
    font-size: 30px;
  }
  .welcomeContainer > p {
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
}
@media screen and (min-width: 1200px) {
  .welcomeContainer {
    /* border: solid; */
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }
  .welcomeContainer > h1 {
    text-align: center;
    font-size: 30px;
  }
  .welcomeContainer > p {
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
  }
}
