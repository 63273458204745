.our1Container {
  /* border: solid red; */
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  gap: 30px;
}
.our1Wrapper {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our1Content1 {
  /* border: solid blue; */
  height: 60vh;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
}

.our1Item1 {
  /* border: solid brown; */
  width: 100%;
  height: 90%;
  background-color: var(--customTransColor);
}
.our1head {
  display: flex;
  justify-content: center;
  align-items: center;
}
.our1head > h2 {
  font-size: 30px;
}
.our1head > h2 > span {
  color: var(--customColor);
}
.our1Item1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.our1Item1 > p {
  /* border: solid black; */
  height: 100%;
  font-size: 20px;
  width: 70%;
  line-height: 30px;
  color: white;
  letter-spacing: 1px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.our1Content2 {
  /* border: solid green; */
  height: 60vh;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.our1Item2 {
  /* border: solid brown; */
  height: 90%;
  width: 80%;
}
.our1Item2 > img {
  height: 100%;
  width: 100%;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .our1Container {
    /* border: solid red; */
    min-width: 300px;
    height: 100%;
  }
  .our1Wrapper {
    /* border: solid; */
    flex-direction: column-reverse;
  }
  .our1Content1 {
    /* border: solid blue; */
    height: 100%;
    width: 100%;
  }

  .our1Item1 {
    /* border: solid brown; */
    width: 100%;
    height: 60%;
  }

  .our1Item1 > p {
    /* border: solid black; */
    height: 100%;
    font-size: 20px;
    width: 100%;
    line-height: 30px;
    color: white;
    padding: 20px;
  }
  .our1Content2 {
    /* border: solid green; */
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .our1Item2 {
    /* border: solid brown; */
    height: 100%;
    width: 100%;
  }
  .our1Item2 > img {
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .our1Container {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    gap: 30px;
  }
  .our1Wrapper {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .our1Content1 {
    /* border: solid blue; */
    height: 60vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .our1Item1 {
    /* border: solid brown; */
    width: 100%;
    height: 90%;
    background-color: var(--customTransColor);
  }
  .our1head {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .our1Item1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .our1Item1 > p {
    /* border: solid black; */
    height: 100%;
    font-size: 20px;
    width: 70%;
    line-height: 30px;
    color: white;
    letter-spacing: 1px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .our1Content2 {
    /* border: solid green; */
    height: 60vh;
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .our1Item2 {
    /* border: solid brown; */
    height: 90%;
    width: 100%;
  }
  .our1Item2 > img {
    height: 100%;
    width: 100%;
  }
}
@media screen and (min-width: 990px) {
  .our1Container {
    /* border: solid red; */
    width: 90%;
  }

  .our1Item1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .our1Item1 > p {
    /* border: solid black; */
    height: 100%;
    font-size: 30px;
    width: 70%;
    line-height: 40px;
  }
}
@media screen and (min-width: 990px) {
  .our1Container {
    /* border: solid red; */
    width: 90%;
  }

  .our1Item1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .our1Item1 > p {
    /* border: solid black; */
    height: 100%;
    font-size: 30px;
    width: 70%;
    line-height: 30px;
  }
}
