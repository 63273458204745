.ArchitectsWrapper {
  /* border: solid; */
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}
.architectsHeadContainer {
  /* border: solid red; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}
.architectsHeadContainer > h1 {
  color: var(--customColor);
  width: 100%;
  text-align: center;
}
.architectsHeadContainer > h2 {
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.architectsHeadContainer > h2 > span {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}

.architectsHeadContainer > h3 {
  color: var(--customColor);
  text-align: center;
  width: 100%;
  font-size: 25px;
}
.architectsHeadContainer > h4 {
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.architectsHeadContainer > h5 {
  text-align: start;
  width: 100%;
  font-size: 25px;
}
.architectsHeadContainer > h5 > span,
.architectsHeadContainer > h4 > span {
  color: var(--customColor);
}
.architectsHeadContainer > li {
  list-style: none;
  margin: 10px;
  font-size: 20px;
  text-align: justify;
}
.architectsHeadContainer > li > h6 {
  font-size: 20px;
  text-align: justify;
  color: var(--customColor);
}
.architectsHeadContainer > li > a {
  text-decoration: none;
  color: var(--customColor);
}

.architectsHeadContainer > li > span {
  color: var(--customColor);
  font-weight: bold;
}
.architectsHeadContainer > li > ul > li {
  list-style: none;
  margin: 10px;
}
.architectsHeadContainer > li > ul > li > span {
  color: var(--customColor);
  font-weight: bold;
}
.architectsHeadContainer > li > ul > li > p > a {
  color: var(--customColor);
  text-decoration: none;
}

.architectsHeadContainer > p {
  text-align: justify;
  font-size: 20px;
}
.architectsHeadContainer > p > a {
  color: var(--customColor);
  text-decoration: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .ArchitectsWrapper {
    /* border: solid; */
    /* height: 70vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
  }
  .architectsHeadContainer {
    /* border: solid red; */
    padding: 20px;
  }
  .architectsHeadContainer > h2 {
    text-align: center;
    font-size: 20px;
  }
  .architectsHeadContainer > h3 {
    text-align: center;
    font-size: 20px;
  }
  .architectsHeadContainer > h4 {
    text-align: center;
    font-size: 20px;
  }
  .architectsHeadContainer > h5 {
    text-align: center;
    font-size: 20px;
  }
}
