.constructionTypeMain {
  /* border: solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}
.constructionsTypesContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.constructionTypeHead {
  /* border: solid blue; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.constructionTypeHead > h2 {
  font-size: 30px;
  text-align: center;
}
.constructionTypeHead > h2 > span {
  color: var(--customColor);
}
.constructionTypeHead > p {
  font-size: 18px;
}
.packageMainContainer {
  margin-top: 50px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .constructionTypeHead > p {
    font-size: 18px;
    text-align: justify;
  }
}
