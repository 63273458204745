.inspireContainer {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
}
.inspireHeadContainer > h2 {
  font-size: 30px;
  height: 30%;
  text-align: center;
}
.inspireHeadContainer > h2 > span {
  color: var(--customColor);
}
.inspireHeadContainer > p {
  font-size: 20px;
  text-align: center;
}
.inspireMainContainer {
  /* border: solid blue; */
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.inspireSliderContainer {
  /* border: solid red; */
  width: 90%;
  height: 90%;
  height: 100%;
}
.inspireSliderContent {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  flex-wrap: wrap;
  padding: 10px;
}
/* .inspireCardContainer {
  border: solid brown;
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 0px;
} */
.inspireImgContent1 {
  /* border: solid red; */
  width: 40%;
  height: 350px;
  margin: 20px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
/* .inspireImgContent2 {
  border: solid blue;
  width: 20%;
  height: 350px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.inspireImgContent3 {
  /* border: solid green; */
  width: 20%;
  height: 350px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border-radius: 20px; */
}
#inspireImgContent > a > img {
  /* border: solid; */
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
#inspireImgContent > a {
  position: relative;
  width: 100%;
  height: 100%;
}
#inspireImgContent > a > h3 {
  color: white;
  background-color: rgba(0, 0, 0, 0.46);
  padding: 5px;
  position: absolute;
  border-radius: 10px;
  bottom: 30px;
  left: 20px;
  text-align: center;
  letter-spacing: 1px;
}
.inspirePopUp {
  /* border: solid pink; */
  height: 100%;
  width: 100%;
  position: fixed;
  top: 110px;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.523);
  backdrop-filter: blur(10px);
  display: none;
  justify-content: center;
  align-items: center;
}

.popXIcon {
  font-size: 30px;
  cursor: pointer;
  color: white;
}
.interiorPopSlider {
  /* border: solid rebeccapurple; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.inspireSliderPopContainer {
  /* border: solid yellow; */
  height: 80%;
  width: 70%;
  gap: 10px;
  padding: 0px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  margin-bottom: 70px;
}
.inspireSliderPopContent {
  /* border: solid blue; */
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inspireSliderPopContent > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.inspirePopUpExit {
  /* border: solid red; */
  position: fixed;
  right: 30px;
  top: 30px;
  z-index: 3;
}
.interiorTypeCardNavi {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.interiorTypeCardNavi > button {
  border-radius: 10px;
  color: white;
  background-color: var(--customColor);
  padding: 10px;
  font-weight: bold;
  cursor: pointer;
  outline: none;
  border: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .inspireImgContent1 {
    /* border: solid red; */
    min-width: 300px;
    height: 350px;
  }
  .inspirePopUp {
    /* border: solid green; */
    height: 100vh;
    width: 100%;
    top: 60px;
  }
  .interiorPopSlider {
    /* border: solid red; */
    width: 80%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .inspireSliderPopContainer {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    gap: 30px;
    padding: 10px;
  }
  .inspireSliderPopContent {
    /* border: solid white; */
    height: 100%;
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .inspireSliderPopContent > img {
    /* border: solid; */
    height: 400px;
    width: 100%;
    object-fit: contain;
    padding: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .inspireImgContent1 {
    /* border: solid red; */
    min-width: 300px;
    height: 350px;
  }
  .inspirePopUp {
    /* border: solid green; */
    height: 90vh;
    width: 100%;
    top: 60px;
  }
  .interiorPopSlider {
    /* border: solid red; */
    width: 80%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .inspireSliderPopContainer {
    /* border: solid green; */
    height: 100%;
    width: 100%;
    gap: 30px;
    padding: 10px;
  }
  .inspireSliderPopContent {
    /* border: solid white; */
    height: 100%;
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .inspireSliderPopContent > img {
    /* border: solid; */
    height: 400px;
    width: 100%;
    object-fit: contain;
    padding: 5px;
  }
}
