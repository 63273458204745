.CommercialInteriorContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.commercialMainContainer {
  /* border: solid red; */
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  padding: 20px;
}
.commercialHeadContainer {
  /* border: solid green; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.commercialHeadContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.commercialHeadContent > h2 {
  font-size: 30px;  
}
.commercialHeadContent > h2 > span {
  color: var(--customColor);
}
.commercialParaContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.commercialParaContent > p {
  font-size: 20px;
  text-align: center;
  width: 100%;
}
.commercialMainContent {
  /* border: solid; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.commercialCardContainer {
  /* border: solid red; */
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.commercialCardImg {
  /* border: solid blue; */
  height: 85%;
  /* display: inline-block; */
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.commercialCardImg > img {
  width: 100%;
  display: block;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.commercialCardHead {
  /* border: solid; */
  width: 100%;
  color: white;
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  justify-content: flex-start;
  padding: 2px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-items: center;
  background-color: var(--customColor);
}
.commercialCardHead > h3 {
  margin-left: 10px;
  font-size: 20px;
}
@media screen and (min-width: 320px) {
  .commercialMainContainer {
    /* border: solid red; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  .commercialHeadContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .commercialMainContent {
    /* border: solid; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .commercialCardContainer {
    /* border: solid red; */
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    margin-top: 20px;
  }
  .commercialCardImg {
    /* border: solid blue; */
    height: 100%;
    width: 120%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
  }
  .commercialCardImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media screen and (min-width: 768px) {
  .commercialMainContainer {
    /* border: solid red; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  .commercialHeadContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .commercialMainContent {
    /* border: solid; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .commercialCardContainer {
    /* border: solid red; */
    height: 40vh;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    margin-top: 10px;
  }
  .commercialCardImg {
    /* border: solid blue; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
  }
  .commercialCardImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media screen and (min-width: 990px) {
  .commercialMainContainer {
    /* border: solid red; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  .commercialHeadContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .commercialMainContent {
    /* border: solid; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .commercialCardContainer {
    /* border: solid red; */
    height: 40vh;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    margin-top: 10px;
  }
  .commercialCardImg {
    /* border: solid blue; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
  }
  .commercialCardImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
@media screen and (min-width: 1200px) {
  .commercialMainContainer {
    /* border: solid red; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
  }
  .commercialHeadContainer {
    /* border: solid green; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .commercialMainContent {
    /* border: solid; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .commercialCardContainer {
    /* border: solid red; */
    height: 40vh;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
    margin-top: 10px;
  }
  .commercialCardImg {
    /* border: solid blue; */
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
  }
  .commercialCardImg > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}
