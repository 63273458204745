.project-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
}
.projectHeader-container {
  width: 100%;
  height: 600px;
  position: relative;
}
.projectHeader-container > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.projectHeader-cover {
  /* border: solid; */
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.307);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}
.projectHeader-cover > h2 {
  color: white;
  font-size: 60px;
  text-align: center;
}
.projectHeader-cover > p {
  color: white;
  font-size: 20px;
  width: 70%;
  font-weight: 600;
  text-align: center;
}
.projectMain-container {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.projectMain-container > a {
  text-decoration: none;
  color: var(--customColor);
}
.projectMain-content {
  width: 450px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 20px;
}
.projectMain-imgContainer {
  width: 100%;
  height: 80%;
  overflow: hidden;
}
.projectMain-imgContainer > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 1s ease-in-out;
}
.projectMain-content:hover .projectMain-imgContainer > img:hover {
  transform: scale(1.2);
}
.projectMain-txtContainer {
  /* border: solid blue; */
  width: 100%;
  padding: 10px 10px 10px 5px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  background-color: var(--customWhite);
}
.projectMain-txtContainer > span {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.projectMain-txtContainer > span > h6 {
  font-size: 15px;
}
.projectMain-txtContainer > a {
  text-decoration: none;
}
.projectMain-txtContainer > a > h3 {
  font-size: 20px;
  text-align: start;
  color: black;
  transition: 200ms ease-in-out;
}

.projectMain-txtContainer > a > h3:hover {
  color: var(--customColor);
}
#projectHeaderId {
  height: 300px;
}
.pagination {
  padding: 20px;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .projectHeader-container {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .projectHeader-cover > h2 {
    color: white;
    font-size: 30px;
  }
  .projectMain-container {
    /* border: solid red; */
    padding: 10px;
  }
  .projectMain-content {
    width: 300px;
    height: 300px;
    margin: 10px;
  }
}
