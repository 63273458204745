.categoryDoorsAndWindows {
  padding: 20px;
  width: 100%;
}
.categoryDoorsAndWindows-Wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.categoryDoorsAndWindowsCard {
  /* border: solid green; */
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0 0 10px grey;
}
.categoryDoorsAndWindowsCard-Img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}
.categoryDoorsAndWindowsCard-Btn {
  /* border: solid brown; */
  width: 100px;
  height: 40px;
}
.categoryDoorsAndWindowsCard-Title > h2 {
  color: var(--customColor);
  text-align: justify;
}
.categoryDoorsAndWindowsCard-Btn > button {
  width: 100%;
  height: 100%;
  padding: 5px;
  font-size: 15px;
  background-color: var(--customColor);
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.categoryDoorsAndWindowsCard-Btn > button > a {
  text-decoration: none;
  color: white;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .categoryDoorsAndWindowsCard {
    /* border: solid green; */
    width: 350px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
}
