.whatContainer {
  /* border: solid red; */
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: var(--customWhite); */
}

.whatHeadContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.whatHeadContainer > h2 {
  font-size: 30px;
  text-align: center;
}
.whatHeadContainer > h2 > span {
  color: var(--customColor);
}
.whatMainContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  height: 100%;
}
.whatCardContainer {
  /* border: solid blue; */
  width: 300px;height: 300px;
  margin: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  background-color: var(--customWhite);
}
.whatCardHeadContainer {
  /* border: solid red; */
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whatImgContainer {
  /* border: solid green; */
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.whatImgContainer > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.whatTxtContainer {
  /* border: solid brown; */
  height: 40%;
  width: 90%;
}
.whatCardHeadContainer > h3 {
  text-align: center;
  color: var(--customColor);
}
.whatTxtContainer > p {
  font-size: 15px;
  text-align: justify;
  color: black;
}
@media screen and (min-width: 320px) {
  .whatContainer {
    /* border: solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .whatHeadContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .whatMainContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
  }
  .whatHeadContainer > h2 {
    font-size: 25px;
    text-align: center;
  }
  .whatCardContainer {
    /* border: solid blue; */
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    margin: 40px;
  }
  .whatCardHeadContainer {
    /* border: solid red; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatImgContainer {
    border: solid var(--customWhite);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatImgContainer > img {
    width: 100%;
    height: 90%;
    object-fit: contain;
  }
  .whatTxtContainer {
    /* border: solid brown; */
    height: 100%;
    width: 90%;
  }
  .whatCardHeadContainer > h3 {
    text-align: center;
    font-size: 15px;
  }
  .whatTxtContainer > p {
    font-size: 15px;
    text-align: justify;
  }
}
@media screen and (min-width: 768px) {
  .whatContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .whatHeadContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .whatMainContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 100%;
  }
  .whatCardContainer {
    /* border: solid blue; */
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    margin: 50px;
  }
  .whatCardHeadContainer {
    /* border: solid red; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatImgContainer {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatImgContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .whatTxtContainer {
    /* border: solid brown; */
    height: 40%;
    width: 90%;
  }
  .whatCardHeadContainer > h3 {
    text-align: center;
  }
  .whatTxtContainer > p {
    font-size: 15px;
    text-align: justify;
  }
}
@media screen and (min-width: 990px) {
  .whatContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .whatHeadContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .whatMainContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
  }
  .whatCardContainer {
    /* border: solid blue; */
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    margin: 50px;
  }
  .whatCardHeadContainer {
    /* border: solid red; */
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatImgContainer {
    /* border: solid green; */

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--customWhite);
  }
  .whatImgContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .whatTxtContainer {
    /* border: solid brown; */
    height: 40%;
    width: 90%;
  }
  .whatCardHeadContainer > h3 {
    text-align: center;
  }
  .whatTxtContainer > p {
    font-size: 14px;
    text-align: justify;
  }
}
@media screen and (min-width: 1200px) {
  .whatContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .whatHeadContainer {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .whatHeadContainer > h2 {
    font-size: 30px;
    text-align: center;
  }
  .whatMainContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100%;
  }
  .whatCardContainer {
    /* border: solid blue; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0px;
    margin: 50px;
  }
  .whatCardHeadContainer {
    /* border: solid red; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .whatImgContainer {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--customWhite);
  }
  .whatImgContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .whatTxtContainer {
    /* border: solid brown; */
    height: 40%;
    width: 90%;
    /* background-color: var(--customColor); */
  }
  .whatCardHeadContainer > h3 {
    text-align: center;
  }
  .whatTxtContainer > p {
    font-size: 15px;
    text-align: justify;
  }
}
