.footerContainer {
  /* border: solid; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: var(--customGradient); */
  background-color: var(--customWhite);
  margin-top: 0px;
}
.footer1 {
  /* border: solid greenyellow; */
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

#BioFooter {
  /* border: solid blue; */
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.footerContent {
  /* border: solid red; */
  height: 40vh;
  width: 15%;
}
.bioFooterContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.bioFooterContainer > img {
  width: 50%;
  height: 50%;
  object-fit: contain;
}
.bioFooterContainer > p {
  /* border: solid; */
  width: 90%;
}
.footerContent > ul {
  /* border: solid; */
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.footerContent > ul > h2 {
  color: var(--customColor);
}
.footerContent > ul > li {
  /* border: solid; */
  width: 90%;
  list-style: none;
  margin: 10px;
  text-align: start;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}
.footerContent > ul > li > p > a {
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-decoration: none;
  color: black;
}
.footerContent > ul > li > p > a:hover {
  color: var(--customColor);
}
.footer2 {
  /* border: solid brown; */
  height: 10vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background-color: var(--customColor);
}
.footer2 > li {
  /* border: solid; */
  height: 100%;
  width: fit-content;
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: white;
  font-size: 20px;
  transition: 300ms ease-in-out;
}
.footer2 > li > a > img {
  /* border: solid; */
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(1.4);
}
.socialIcon {
  font-size: 30px;
}
.socialIcon:hover {
  color: var(--customColor);
}
#socialFooter {
  /* border: solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#socialFooter > h2 {
  color: var(--customColor);
}
.socialListContainer {
  /* border: solid brown; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px;
  height: 100%;
  width: 100%;
}
.socialList {
  /* border: solid red; */
  width: 25%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px;
}
.socialList > a {
  /* border: solid red; */
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: black;
}
.footer2 > li > a {
  /* border: solid red; */
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: black;
}

@media screen and (min-width: 320px) {
  .footerContainer {
    /* border: solid; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--customGradient); */
  }
  .footer1 {
    /* border: solid greenyellow; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    margin-top: 20px;
  }
  #footerContent1 > ul {
    /* border: solid; */
    width: 100%;
    gap: 20px;
  }
  #BioFooter {
    /* border: solid blue; */
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerContent {
    /* border: solid red; */
    height: 100%;
    width: 90%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerContent > ul > h2 {
    /* border: solid; */
    text-align: center;
    width: 100%;
  }
  .footerContent > ul {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerContent > ul > li > a {
    color: black;
    text-decoration: none;
  }
  .footerContent > ul > li > a:hover {
    color: var(--customColor);
  }
  #footerContent1 > ul > li {
    /* border: solid; */
    width: 100%;
    margin: 0px;
  }
  .bioFooterContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .bioFooterContainer > img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  .bioFooterContainer > p {
    /* border: solid; */
    width: 90%;
  }

  .footer2 {
    /* border: solid brown; */
    height: 10vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: var(--customColor);
  }
  .footer2 > li {
    width: 30%;
    font-size: 15px;
    list-style: none;
    color: white;
  }
}
@media screen and (min-width: 768px) {
  .footerContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--customGradient); */
  }
  .footer1 {
    /* border: solid greenyellow; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;

    margin-top: 20px;
  }

  #BioFooter {
    /* border: solid blue; */
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerContent {
    /* border: solid red; */
    height: 100%;
    width: 90%;
    margin-top: 20px;
  }
  .bioFooterContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .bioFooterContainer > img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  .bioFooterContainer > p {
    /* border: solid; */
    width: 90%;
    font-weight: bold;
  }

  .footerContent > ul > li {
    list-style: none;
    margin: 10px;
  }
  .footer2 {
    /* border: solid brown; */
    height: 10vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background-color: var(--customColor);
  }
  .footer2 > li {
    list-style: none;
    color: white;
  }
}
@media screen and (min-width: 990px) {
  .footerContainer {
    /* border: solid; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--customGradient); */
  }
  .footer1 {
    /* border: solid greenyellow; */
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    margin-top: 0px;
  }

  #BioFooter {
    /* border: solid blue; */
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
  }
  .footerContent {
    /* border: solid red; */
    height: 100%;
    width: 20%;
  }
  .bioFooterContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .bioFooterContainer > img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  .bioFooterContainer > p {
    /* border: solid; */
    width: 90%;
  }

  .footerContent > ul > li {
    list-style: none;
    margin: 10px;
  }
  .footer2 {
    /* border: solid brown; */
    height: 10vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background-color: var(--customColor);
  }
  .footer2 > li {
    list-style: none;
    color: white;
  }
}
@media screen and (min-width: 1200px) {
  .footerContainer {
    /* border: solid; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: var(--customGradient); */
    margin-top: 0px;
  }
  .footer1 {
    /* border: solid greenyellow; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 0px;
  }

  #BioFooter {
    /* border: solid blue; */
    width: 30%;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footerContent {
    /* border: solid red; */
    height: 40vh;
    width: 15%;
  }
  .bioFooterContainer {
    /* border: solid red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }
  .bioFooterContainer > img {
    width: 50%;
    height: 50%;
    object-fit: contain;
  }
  .bioFooterContainer > p {
    /* border: solid; */
    width: 90%;
    font-weight: lighter;
  }
  .footerContent > ul {
    /* border: solid; */
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  .footerContent > ul > h2 {
    color: var(--customColor);
  }
  .socialList {
    /* border: solid; */
    width: 90%;
    list-style: none;
    margin: 10px;
    text-align: start;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
  }
  #footerContent1 > ul {
    /* border: solid; */
    width: 100%;
    gap: 0px;
  }
  .footerContent > ul > li > a {
    list-style: none;
    margin: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .footer2 {
    /* border: solid brown; */
    height: 15vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background-color: var(--customColor);
  }
  .footer2 > li {
    width: 30%;
    list-style: none;
    color: white;
  }

  .footer2 > li > a > img {
    /* border: solid; */
    height: 80%;
    width: 100%;
    object-fit: contain;
  }
  .footer2 > li {
    /* border: solid; */
    height: 100%;
    width: 30%;
    list-style: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    transition: 300ms ease-in-out;
    font-size: 20px;
  }
  .socialIcon {
    font-size: 30px;
  }
  .socialIcon:hover {
    color: var(--customColor);
  }
  #socialFooter {
    /* border: solid green; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  #socialFooter > h2 {
    color: var(--customColor);
  }
  .socialListContainer {
    /* border: solid brown; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 0px;
    height: 100%;
    width: 100%;
  }
  .socialList {
    /* border: solid red; */
    width: 25%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
  }
  .socialList > a {
    /* border: solid red; */
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
}
