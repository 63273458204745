.navBarContainer {
  /* border: solid; */
  height: 17vh;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navBarLogoContainer {
  /* border: solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.navBarLogoContainer > a > img {
  width: 200px;
}
.navBarContent1 {
  /* border: solid red; */
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navBarContent2 {
  /* border: solid blue; */
  width: 80%;
  height: 100%;
}
.navBarItem1 {
  /* border: solid; */
  height: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.navInnerItem {
  /* border: solid yellow; */
  height: 100%;
  width: 25%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 20px;
  background-color: var(--customWhite);
}
#navInnerItem1 {
  border-bottom-left-radius: 20px;
}
.navIcon {
  color: black;
}
.navInnerItem > p {
  color: var(--customColor);
}
.navLine {
  border-right: 1px solid black;
  height: 80%;
}
.navInnerItem > p > a {
  margin: 0;
  color: black;
  text-decoration: none;
}
.navBarItem2 {
  /* border: solid green; */
  height: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
.navBarItem2 > li {
  /* border: solid red; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.navBarItem2 > li > a {
  font-size: 20px;
  color: black;
  list-style: none;
  text-decoration: none;
}
.navBarItem2 > li > span {
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: black;
}
.dropScroll {
  /* border: solid red; */
  height: 300px;
  width: fit-content;
  overflow: auto;
  position: absolute;
  top: 70px;
  left: -10px;
  z-index: 10;
  visibility: hidden;
  transform: translateY(20px);
  opacity: 0;
  transition: 300ms ease-in-out;
}
/* Target the scrollbar */
.dropScroll::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

/* Track of the scrollbar */
.dropScroll::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background color of the scrollbar track */
}

/* Thumb of the scrollbar */
.dropScroll::-webkit-scrollbar-thumb {
  background-color: var(--customColor); /* Color of the scrollbar thumb */
  border-radius: 6px; /* Rounded corners of the scrollbar thumb */
  border: 3px solid #f1f1f1; /* Space between thumb and track */
}

/* On hover effect */
.dropScroll::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker shade on hover */
}

.dropDown {
  border-top: 5px solid var(--customColor);
  background-color: white;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 15px;
  box-shadow: 0 0 5px grey;
}
.dropDown > li > a {
  color: black;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.navBarItem2 > li > span:hover {
  text-decoration: underline;
}
.navBarItem2 > li > span:hover .dropScroll {
  visibility: visible;
  transform: translateY(0px);
  opacity: 1;
}
.navBarItem2 > li > a:hover {
  text-decoration: underline;
}

.mainMenu {
  /* border: solid; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* ------------------------------------------------------------------------- */
.hamIcon {
  color: var(--customColor);
  margin-right: 0;
  font-size: 25px;
  margin-right: -100px;
}
.respMenu {
  /* border: solid blue; */
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0px;
  height: 100%;
  width: 80%;
  overflow-y: scroll;
  display: none;
  transition: 1s ease-in-out;
}
.respMenuScroll {
  height: 100%;
  width: 100%;
  /* border: solid red; */
}
.main {
  position: relative;
}
.cross {
  position: fixed;
  right: 20px;
  top: 0;
}

.respMenuScroll > ul {
  border-bottom: solid 1px black;
  background-color: var(--customWhite);
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.respMenuScroll > ul > h2 {
  /* border: solid green; */
  color: white;
  height: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 150%;
  font-weight: lighter;
}
.respMenuScroll > ul > h2 {
  color: white;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 150%;
  font-weight: lighter;
}
.respMenuScroll > ul > h2 > a {
  color: black;
  text-decoration: none;
  padding: 20px;
}
.respMenuScroll > ul > span > li {
  list-style: none;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.respIcon {
  color: black;
}
.respMenuSpace {
  height: 300px;
}
@media screen and (min-width: 320px) {
  .navBarItem1,
  .navBarItem2 {
    display: none;
  }
  .navBarLogoContainer > {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navBarLogoContainer > a > img {
    width: 150px;
  }
  .navBarContainer {
    /* border: solid; */
    height: 10vh;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navBarContent1 {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 0%;
  }
  .navBarLogoContainer {
    /* border: solid; */
    height: 100%;
  }
  .navBarLogoContainer > img {
    height: 100%;
  }
  .menuContent {
    display: none;
  }
  .mainMenu {
    display: flex;
  }
}
@media screen and (min-width: 768px) {
  .navBarItem1,
  .navBarItem2 {
    display: none;
  }
  .navBarLogoContainer > {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navBarContainer {
    /* border: solid; */
    height: 10vh;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navBarContent1 {
    /* border: solid; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 250px;
  }
  .navBarLogoContainer {
    /* border: solid; */
    height: 100%;
  }
  .navBarLogoContainer > img {
    height: 100%;
  }

  .mainMenu {
    display: flex;
  }
  .respMenu {
    /* border: solid blue; */
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0px;
    height: 100%;
    width: 40%;
    overflow-y: scroll;
    display: none;
    transition: 1s ease-in-out;
  }
}
@media screen and (min-width: 990px) {
  .navBarContainer {
    /* border: solid; */
    height: 17vh;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 30px; */
  }

  .navBarContent1 {
    /* border: solid red; */
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
  .navBarContent1 > img {
    height: 90%;
    width: 90%;
  }

  .navBarContent2 {
    /* border: solid blue; */
    width: 80%;
    height: 100%;
  }
  .navBarItem1 {
    /* border: solid; */
    height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .navInnerItem {
    /* border: solid yellow; */
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 18px;
  }
  #navInnerItem1 {
    width: 30%;
  }

  .navInnerItem > p {
    color: white;
  }

  .navBarItem2 {
    /* border: solid green; */
    height: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }
  .navBarItem2 > li {
    font-size: 20px;
    color: var(--customColor);
    list-style: none;
  }
  .navBarItem2 > li > a > img {
    width: 40px;
    list-style: none;
  }
  .mainMenu {
    display: none;
  }
  .respMenu {
    display: none;
  }
}
@media screen and (min-width: 1200px) {
  .navBarContainer {
    /* border: solid; */
    height: 17vh;
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navBarContent1 {
    /* border: solid red; */
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navBarContent2 {
    /* border: solid blue; */
    width: 80%;
    height: 100%;
  }
  .navBarItem1 {
    /* border: solid; */
    height: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .navInnerItem {
    /* border: solid yellow; */
    height: 100%;
    width: 40%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 18px;
  }
  #navInnerItem1 {
    width: 20%;
  }

  .navInnerItem > p {
    color: white;
  }

  .navBarItem2 {
    /* border: solid green; */
    height: 60%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }
  .navBarItem2 > li > a {
    font-size: 20px;
    list-style: none;
  }
  .navBarItem2 > li > a > img {
    width: 50px;
    list-style: none;
  }
  .mainMenu {
    display: none;
  }
  .respMenu {
    display: none;
  }
}
