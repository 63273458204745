.ourMissionList {
  width: 100%;
}
.ourMissionList > ul > li > span {
  font-weight: bold;
}
@media screen and (min-width: 320px) {
  .ourMissionList > ul > li {
    font-size: 20px;
    margin: 10px;
  }
}
@media screen and (min-width: 768px) {
  .ourMissionList > ul > li {
    font-size: 10px;
  }
}
@media screen and (min-width: 990px) {
  .ourMissionList > ul > li {
    font-size: 15px;
    margin: -6px;
  }
}
@media screen and (min-width: 1200px) {
  .ourMissionList > ul > li {
    font-size: 20px;
    margin: 0;
  }
}
